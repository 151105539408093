<template>
  <div class="">
    <CreateRangeModal @reload="getRanges()" ref="createRangeModal"></CreateRangeModal>
    <div class="w-100 headerSection d-flex align-items-center justify-content-between">

<router-link class="navRoute" to="/manage/emails/flows/list"><svg
      class="navbar__icon navbar__icon--left mr-3"
      width="8"
      height="14"
      viewBox="0 0 8 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.08671 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
        fill="white"
      />
    </svg> Flow</router-link>

    <div>

      <div class="d-flex align-items-center" v-if="flowDetail.id">
        <p class="m-0 p-0 " style="color:#FEFEFE">Autosave is on</p>
        <div class="ml-5 d-flex align-items-center">
          <p class="m-0 p-0 text-capitalize font-weight-bold" style="font-size:17px;" :style="flowDetail.status == 'live' ? 'color:#FFA201;' : 'color:#73738d;'">{{ flowDetail.status }}</p>

          <span class=" custom-control custom-switch ml-3" >
        <input
          type="checkbox"
          class="custom-control-input cursor-pointer"
          :id="`flowToggle-${flowDetail.id}`"
          :checked="isLive(flowDetail.status)"
          @change="toggleStatus(flowDetail)"
        />
        <!-- v-model="data.item.status" -->
        <label class="custom-control-label cursor-pointer" :for="`flowToggle-${flowDetail.id}`"></label>
      </span>
        </div>
      </div>
    </div>

</div>

    <div class="d-flex">
      <div class="flow-sidebar" style="">
        <transition name="route">
        <DelayTimeDetail
          v-if="activeTabName == 'delayTime-tab'"
          :isLoading="isLoadingStepUpdate"
          @submit="updateStep($event)"
          :selectedObject="selectedObject"
          @cancel="activeTabName = 'actionFlows'"
        ></DelayTimeDetail>
        <SidebarEmailDetail
          v-else-if="activeTabName == 'email-tab'"
          :selectedObject="selectedObject"
          @cancel="activeTabName = 'actionFlows'"
          @edit="(e)=>navigateToEmailContent(e)"
        ></SidebarEmailDetail>
        <FlowsSidebar
          :isLoadingFlow="isLoadingFlow"
          :selectedSegment="getSideBarDetail"
          :isLoadingSave="isLoadingSaveFlow"
          :isActiveTab="activeTabName"
          :isLoadingRange="isLoadingRange"
          :rangeList="rangeList"
          @cancel="closeTriggerSetupHandle"
          @done="getSelectedSegment($event)"
          @saveEmailFlow="saveEmailFlow()"
          :openCreateModal="openCreateRangeModal"
          v-else
        />
        </transition>
      </div>
      <div class="" v-if="!isLoadingFlow">
        <div
          class="flow-content d-flex justify-content-center p-3"
          style="margin-top: 4rem"
        >
          <div style="width: 368px">
            <div
              class="q-card quizell-item-card"
              style="cursor: pointer"
              @click="()=>{activeTabName = 'triggerSetup';OpenEmailContentIndex=-1;OpenEmailContentCount=0;}"
            >
              <div class="flow-card align-items-center justify-content-center">
                <div class="lighting-svg">
                  <svg
                    width="44"
                    height="44"
                    viewBox="0 0 44 44"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect width="44" height="44" rx="22" fill="#F4F3F5" />
                    <path d="M21 25H16L23 11V19H28L21 33V25Z" fill="#18191C" />
                  </svg>
                </div>
                <div class="trigger-content ml-2">
                  <div class="trigger-content-heading mb-1">Trigger</div>
                  <div class="trigger-content-description" v-if="checkAudienceType('all')">
                    When 
                    <span class="segment-name">
                      any lead 
                    </span>
                    is created.
                  </div>
                  <div class="trigger-content-description" v-else-if="checkAudienceType('partial')&&getSideBarDetail.segment_id">
                    When someone select 
                    <span class="segment-name">
                      {{ getSideBarDetail.segment_title||getSideBarDetail?.segment?.name }}
                    </span>
                  </div>
                  <div class="trigger-content-description" v-else-if="checkAudienceType('partial')&&(!getSideBarDetail.segment_id)">
                    When  score is between  <span class="segment-name">{{ getSideBarDetail.min_score }}</span> and  
                    <span class="segment-name">
                      {{ getSideBarDetail.max_score }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <draggable
              class="dragArea list-group"
              :list="getActions"
              group="actions"
              v-bind="dragOptions"
              chosen-class="chosen"
              drag-class="drag"
              @change="log"
            >
              <span
                v-for="(action, index) in getActions"
                :key="Math.random() + index"
                class="quizell-item-card"
              >
                <div class="droppable-target">
                  <div class="component-node-container">
                    <div
                      class="add-component-node"
                      :class="isFlowDragging ? 'flowDragActive' : null"
                    >
                      <div
                        v-if="isFlowDragging"
                        class="addCompNodeIcon d-flex align-items-center justify-content-center"
                      >
                        <b-icon icon="plus-circle" font-scale="1"></b-icon>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="flow-card d-flex justify-content-center align-items-center"
                  style="
                    border: none;
                    border: 1px solid rgba(179, 175, 182, 0.5);
                    background: #fff;
                    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
                  "
                  v-if="action.is_loading"
                >
                  <b-spinner label="Loading..."></b-spinner>
                </div>
                <!-- :style="{border: activeTabName=='email-tab'?'1px solid #4D1B7E':'none'}" -->
                <div
                  class="flow-card bg-white align-items-center justify-content-start"
                  style="
                    border: 1px solid rgba(179, 175, 182, 0.5);
                    background: #fff;
                    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
                  "
                  :style="{
                    border:
                      activeTabName == 'email-tab' && action.is_active == true
                        ? '1px solid #4D1B7E'
                        : 'none',
                  }"
                  @click="activeTab('email-tab', index, action)"
                  v-else-if="action.step_type == 'email'"
                >
                  <div class="lighting-svg">
                    <svg
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="44" rx="22" fill="#F4F3F5" />
                      <path
                        d="M30 18L22 23L14 18V16L22 21L30 16M30 14H14C12.89 14 12 14.89 12 16V28C12 28.5304 12.2107 29.0391 12.5858 29.4142C12.9609 29.7893 13.4696 30 14 30H30C30.5304 30 31.0391 29.7893 31.4142 29.4142C31.7893 29.0391 32 28.5304 32 28V16C32 14.89 31.1 14 30 14Z"
                        fill="#18191C"
                      />
                    </svg>
                  </div>
                  <div class="trigger-content ml-2 w-100">
                    <div
                      class="trigger-content-heading d-flex justify-content-between mb-1"
                    >
                      <span>
                        {{ action.step_data.name }}
                      </span>

                      <b-dropdown dropright class="" id="collection-detail">
                        <template slot="button-content">
                          <div class="text-dark">
                            <i class="fa-solid fa-ellipsis"></i>
                          </div>
                        </template>
                        <b-dropdown-item
                          class="topbarLink d-flex align-items-center"
                          @click.stop="navigateToEmailContent(action.step_data.uuid)"
                        >
                          Edit</b-dropdown-item
                        >
                        <b-dropdown-item
                          class="topbarLink d-flex align-items-center"
                          @click.stop="cloneStep(action.id)"
                        >
                          Clone</b-dropdown-item
                        >
                        <b-dropdown-item
                          class="topbarLink d-flex align-items-center"
                          @click.stop="deleteStep(action, index)"
                        >
                          Remove</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>
                    <div class="trigger-content-description">
                      {{ action.step_data.subject }}
                    </div>
                  </div>
                </div>

                <!--  -->
                <div
                  class="flow-card align-items-center justify-content-start bg-white"
                  :style="{
                    border:
                      activeTabName == 'delayTime-tab' &&
                      action.is_active == true
                        ? '1px solid #4D1B7E'
                        : '1px solid rgb(255, 162, 1)',
                  }"
                  @click="activeTab('delayTime-tab', index, action)"
                  v-else-if="action.step_type == 'delay'"
                >
                  <div class="lighting-svg">
                    <svg
                      width="44"
                      height="44"
                      viewBox="0 0 44 44"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="44" height="44" rx="22" fill="#F4F3F5" />
                      <path
                        d="M22 12C20.6868 12 19.3864 12.2587 18.1732 12.7612C16.9599 13.2638 15.8575 14.0003 14.9289 14.9289C13.0536 16.8043 12 19.3478 12 22C12 24.6522 13.0536 27.1957 14.9289 29.0711C15.8575 29.9997 16.9599 30.7362 18.1732 31.2388C19.3864 31.7413 20.6868 32 22 32C24.6522 32 27.1957 30.9464 29.0711 29.0711C30.9464 27.1957 32 24.6522 32 22C32 20.6868 31.7413 19.3864 31.2388 18.1732C30.7362 16.9599 29.9997 15.8575 29.0711 14.9289C28.1425 14.0003 27.0401 13.2638 25.8268 12.7612C24.6136 12.2587 23.3132 12 22 12ZM26.2 26.2L21 23V17H22.5V22.2L27 24.9L26.2 26.2Z"
                        fill="#FFA201"
                      />
                    </svg>
                  </div>
                  <div class="trigger-content ml-2 w-100">
                    <div
                      class="trigger-content-heading d-flex justify-content-between mb-1"
                    >
                      <div class="trigger-content-description">Delay time:</div>
                      <b-dropdown dropright class="" id="collection-detail">
                        <template slot="button-content">
                          <div class="text-dark">
                            <i class="fa-solid fa-ellipsis"></i>
                          </div>
                        </template>

                        <b-dropdown-item
                          class="topbarLink d-flex align-items-center"
                          @click="cloneStep(action.id)"
                        >
                          Clone</b-dropdown-item
                        >
                        <b-dropdown-item
                          class="topbarLink d-flex align-items-center"
                          @click="deleteStep(action, index)"
                        >
                          Remove</b-dropdown-item
                        >
                      </b-dropdown>
                    </div>

                    <div class="form-section mt-2 d-flex align-items-center">
                      <span class="font-weight-bold" style="color: #73738d">
                        {{ action.step_data.delay_number }}
                        {{ delayTimeText(action) }}
                      </span>
                    </div>
                  </div>
                </div>
              </span>
            </draggable>
            <div class="">
              <div class="droppable-target">
                <div class="component-node-container">
                  <div
                    class="add-component-node"
                    :class="isFlowDragging ? 'flowDragActive' : null"
                  >
                    <div
                      v-if="isFlowDragging"
                      class="addCompNodeIcon d-flex align-items-center justify-content-center"
                      style="text-align: center"
                    >
                      <b-icon
                        icon="plus-circle"
                        style="margin-left: 0.6px; font-size: 5px"
                      ></b-icon>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="trigger-content-description d-flex w-100 justify-content-center"
              >
                <div
                  class="font-weight-bold d-block p-2"
                  style="
                    text-align: center;
                    border: none;
                    border: 1px solid rgba(179, 175, 182, 0.5);
                    background-color: transparent;
                    width: 73px;
                  "
                >
                  EXIT
                </div>
              </div>
            </div>
          </div>
        </div>

        <FullScreenModal
          title="Update your email"
          :visible="isVisibleEMailFlow"
          @close="isVisibleEMailFlow = false"
        >
          <template v-slot:button>
            <Button
              type="submit"
              :is-loading="isLoadingEmail"
              @click="$refs.createEmailFlow.store()"
              >Update Email</Button
            ></template
          >
          <UpdateEmailTemplate
            ref="createEmailFlow"
            :emailDetail="emailDetail"
            :fetchingDataLoading="fetchingDataLoading"
            @stopLoading="
              (modalVisible) => {
                isVisibleEMailFlow = modalVisible;
                isLoadingEmail = false;
                getEmailTemplates();
              }
            "
            @startLoading="isLoadingEmail = true"
          ></UpdateEmailTemplate>
        </FullScreenModal>
      </div>
      <div class="" v-else>
        <FlowSkeleton></FlowSkeleton>
      </div>
    </div>

    <FlowEmailContent @change="getEmailTemplates" ref="FlowEmailContentModal" :id="flowId" :contentId="flowStepContendId" @triggerFetchFlow="triggerFetchFlow"></FlowEmailContent>
    
  </div>
</template>

<script>
import DelayTimeDetail from "./Components/DelayTimeDetail.vue";
import draggable from "vuedraggable";
import FlowsSidebar from "./Sidebar.vue";
import { mapActions, mapGetters } from "vuex";
import FullScreenModal from "../../Layout/FullScreenModal.vue";
import UpdateEmailTemplate from "./UpdateEmailTemplate.vue";
import Button from "../../Layout/Button.vue";
import FlowSkeleton from "./Components/FlowSkeleton.vue";
import FlowEmailContent from "./FlowEmailContent.vue";
import SidebarEmailDetail from './Components/SidebarEmailDetail.vue';
import CreateRangeModal from "./Components/CreateRangeModal.vue";
import axios from 'axios';
export default {
  props: {
    isLoadingFlow: Boolean,
    flowDetail: Object,
  },
  components: {
    CreateRangeModal,
    FlowsSidebar,
    FlowEmailContent,
    draggable,
    DelayTimeDetail,
    SidebarEmailDetail,
    FullScreenModal,
    UpdateEmailTemplate,
    FlowSkeleton,
    Button,
  },
  data() {
    return {

      isLoadingRange:false,
      rangeList:[],
      activeTabName: "actionFlows",
      email_templates: [],
      chosenIndex: -1,
      isVisibleEMailFlow: false,
      selectedSegments: {},
      isLoadingEmail: false,
      isLoadingSaveFlow: false,
      isDisableEmailSelectControl: false,
      isLoadingStepUpdate: false,
      fetchingDataLoading: false,
      emailDetail: {},
      selectedObject: {},
      flowId:null,
      flowStepContendId:null,
      sideBarTriggerFlowDetail:null,
      OpenEmailContentCount:0,
      OpenEmailContentIndex:-1,
    };
  },
  watch: {
    OpenEmailContentIndex(newVal,oldValue){
      if(newVal!=oldValue){
        this.OpenEmailContentCount=0
      }
    }
  },
  computed: {
  getSideBarDetail(){
   
  return this.sideBarTriggerFlowDetail==null?this.getFlowTitleSegment:this.sideBarTriggerFlowDetail
  },
    getActions() {
      return this.flowDetail.flow_steps;
    },
    ...mapGetters(["getFlowCompDragging"]),
    isFlowDragging() {
      return this.getFlowCompDragging;
    },
    getFlowTitleSegment() {
     let  {title, id, segment_id,audience_type,segment_title,segment,score_range_id,ranges}=this.flowDetail
     if(ranges)
     {

       const {max_score,min_score} = ranges;
  
        return {title,max_score,min_score, id, segment_id,audience_type,segment_title,segment,score_range_id};
     }
     return {title, id, segment_id,audience_type,segment_title,segment,score_range_id};
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost",
        chosenClass: "custom-chosen",
      };
    },
  },
  methods: {
    async getRanges(){
      this.isLoadingRange=true
      try{

      this.rangeList = await axios.get('/api/v1/flow/score-range/list')
      this.rangeList=this.rangeList.data.data
      }
      catch{
        this.toast("Error occurred while getting range list");
      }
      this.isLoadingRange=false
    },
    openCreateRangeModal(){
      this.$refs.createRangeModal.open();
    },
    checkAudienceType(type){
      return this.getSideBarDetail?.audience_type==type
    },
    activeTab(active, activeIndex, action) {
      this.activeTabName = active;
      this.OpenEmailContentCount++
      if(this.OpenEmailContentCount>=1&&this.OpenEmailContentIndex==activeIndex&&active=='email-tab')
      {
       this.navigateToEmailContent(action.step_data.uuid)
      }
      
      this.OpenEmailContentIndex=activeIndex
      this.flowDetail.flow_steps.forEach((step, index) => {
        if (activeIndex == index)
          this.flowDetail.flow_steps[index].is_active = true;
        else this.flowDetail.flow_steps[index].is_active = false;
      });
      // this.flowDetail={...this.flowDetail}
      this.selectedObject = { ...action, index: activeIndex };
    },
    async deleteStep(step, index) {
      const flowId = this.flowDetail.id;
      const action = step;
      this.flowDetail.flow_steps.splice(index, 1);
      this.flowDetail.flow_steps.splice(index, 0, { is_loading: true });
      const data = {
        flow_id: flowId,
        id: action.id,
      };
      const response = await this.fetchData({
        axiosMethod: "post",
        resource: "flow",
        method: "deleteStep",
        params: data,
      });
      this.flowDetail.flow_steps.splice(index, 1);
      if (response && response.status == "success") {
        this.toast("Step deleted successfully!");
      } else {
        this.flowDetail.flow_steps.splice(index, 0, action);
        this.toast("Error while duplicating a step!");
      }
      this.reorderFlows();
      this.activeTabName = "actionFlows";
    },

    async cloneStep(id) {
      const flowId = this.flowDetail.id;
      this.flowDetail.flow_steps.push({ is_loading: true });
      const data = {
        flow_id: flowId,
        id: id,
      };
      const response = await this.fetchData({
        axiosMethod: "post",
        resource: "flow",
        method: "cloneStep",
        params: data,
      });
      this.flowDetail.flow_steps.pop();
      if (response && response.status == "success") {
        this.flowDetail.flow_steps.push(response.data);
        this.toast("Step duplicated successfully!");
      } else {
        this.toast("Error while duplicating a step!");
      }
      this.activeTabName = "actionFlows";
    },
    delayTimeText(action) {
      const delayTypes = {
        days: {
          singular: "Day",
          plural: "Days",
        },
        hours: {
          singular: "Hour",
          plural: "Hours",
        },
        minutes: {
          singular: "Minutes",
          plural: "Minutes",
        },
        seconds: {
          singular: "Second",
          plural: "Seconds",
        },
      };
      const delayCat = delayTypes[action.step_data.delay_category];
      return " " + action.step_data.delay_number <= 1
        ? delayCat.singular
        : delayCat.plural;
    },
    async updateStep(stepData) {
      this.isLoadingStepUpdate = true;
      const data = await this.fetchData({
        axiosMethod: "post",
        resource: "flow",
        method: "updateStep",
        params: stepData,
      });
      if (data && data.status == "success") {
        this.flowDetail[data.index] = data.data;
        // this.flowDetail={...this.flowDetail}
        this.toast("Delay time updated successfully");
        this.activeTabName = "actionFlows";
      } else this.toast("Error while updating delay time");
      this.isLoadingStepUpdate = false;
    },
    async navigateToEmailContent(id) {
      this.flowStepContendId=await id;
      this.flowId=await this.$route.params.id
      this.$refs.FlowEmailContentModal.open();
    },

    async log(e) {
      if (e.added) {
        await this.addSingleStep({
          flow_id: this.flowDetail.id,
          step_type: e.added.element.step_type,
          step_no: e.added.newIndex,
        });
      }

      this.reorderFlows();
    },
    async reorderFlows() {
      let updatedFlows = { ...this.flowDetail };
      let sortArr = this.flowDetail.flow_steps.map(({ id }, index) => {
        updatedFlows.flow_steps[index].step_no = index;
        this.flowDetail.flow_steps;
        return {
          id: id,
          step_no: index,
        };
      });

      const data = {
        flow_id: this.flowDetail.id,
        flow_steps: sortArr,
      };
      const response = await this.fetchData({
        axiosMethod: "post",
        resource: "flow",
        method: "resort",
        params: data,
      });
      if (response && response.status == 200) {
        this.flowDetail = { ...updatedFlows };
      }
    },
    async addSingleStep(data) {
      const response = await this.fetchData({
        axiosMethod: "post",
        resource: "flow",
        method: "add",
        params: data,
      });
      if (response && response.status == "success") {
        this.toast("Step Added Successfully!");
        this.flowDetail.flow_steps[data.step_no] = response.data;
        this.flowDetail.flow_steps = [...this.flowDetail.flow_steps];
      } else this.toast("something went wrong.");
    },
    ...mapActions(["fetchData"]),
    closeTriggerSetupHandle() {
      this.activeTabName = "actionFlows";
    },
    async getSelectedSegment(selected) {
      const data = {
        ...selected
      };
      this.isLoadingSaveFlow = true;
      const response = await this.fetchData({
        axiosMethod: "put",
        resource: "flow",
        method: "update",
        id: this.$route.params.id,
        params: data,
      });
      if (response && response.status == "success") {
        this.toast("Flow Updated");
        this.flowDetail.title = selected.title;
        this.flowDetail.segment_id = selected.segment_id;
        this.sideBarTriggerFlowDetail=selected;
        // this.flowDetail.segment.id = selected.segment_id;
        // this.flowDetail.segment.name = selected.segment_name||"Quiz";
        this.activeTabName = "actionFlows";
      } else {
        this.toast("something went wrong");
      }
      this.isLoadingSaveFlow = false;
    },
    async getEmailTemplates() {
      this.isDisableEmailSelectControl = true;
      const email_templates = await this.fetchData({
        axiosMethod: "get",
        resource: "email_templates",
        method: "list",
        params: { params: { per_page: 1000 } },
      });
      if (email_templates) {
        this.email_templates = email_templates.data.email_templates.data;
      }
      this.isDisableEmailSelectControl = false;
    },
    async saveEmailFlow() {
      if (this.checkEmailTemplateId()) {
        this.isLoadingSaveFlow = true;
        const email_templates = await this.fetchData({
          axiosMethod: "put",
          resource: "flow",
          method: "update",
          params: this.flowDetail,
          id: this.$route.params.id,
        });
        if (email_templates) {
          this.toast("Flow Successfully Updated");
          this.isLoadingSaveFlow = false;
          return;
        }
        this.toast("Error while updating flow");
        this.isLoadingSaveFlow = false;
      }
    },
    async setEmailNameSubject(template_id, index) {
      const { name, subject } = this.email_templates.find(
        (template) => template.id == template_id
      );
      const emailStep = this.flowDetail.flow_steps[index];
      emailStep.email_template_name = name;
      emailStep.email_template_subject = subject;
      this.flowDetail.flow_steps[index] = emailStep;
    },
    checkEmailTemplateId() {
      let isValidTemplateId = true;
      for (const step of this.flowDetail.flow_steps) {
        if (step.type == "email" && step.email_template_id == "") {
          this.toast("Please Select Email Template");
          isValidTemplateId = false;
          break;
        }
      }
      return isValidTemplateId;
    },
    async getEmailTemplateDetail(template_id) {
      this.isVisibleEMailFlow = true;
      this.fetchingDataLoading = true;
      const templateData = await this.fetchData({
        axiosMethod: "get",
        resource: "email_templates",
        method: "edit",
        id: template_id,
      });

      if (templateData) {
        this.emailDetail = templateData.data;
      }
      this.fetchingDataLoading = false;
    },
    toast(text) {
      this.$toasted.show(text, {
        theme: "toasted-primary",
        position: "bottom-center",
        duration: 2000,
      });
    },

    isLive(status) {
      return status === 'live';
    },
  async  toggleStatus(item) {
      try {
        this.$toasted.show("Updating Status, This may take a while!", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });

        let data = {
          status : this.isLive(item.status) ? 'draft' : 'live'
        }

        const response = await this.fetchData({
        axiosMethod:'put',
        resource:'flow',
        method:'update',
        id:item.uuid,
        params: data,
      });
      if(response)
      this.$toasted.show("Status Updated Successfully", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });

              this.$emit('fetchFlow')


        
      } catch (error) {
        if(error){
          this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
            }
            throw error;
      }
    },

    triggerFetchFlow(){
       this.activeTabName= "actionFlows"
      this.selectedObject = {}
      this.$emit('fetchFlow')
    }
  },
  async mounted() {
   await this.getRanges();
    const {completedCustomDesign,EmailEditId}=this.$route.query
    if(completedCustomDesign&&EmailEditId)
    this.navigateToEmailContent(EmailEditId)
  },
};
</script>

<style scoped>
.delay-time-selection {
  background-color: transparent;
  color: #73738d;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.48px;
  outline: none;
  border: none;
  width: 40%;
}
.create-link {
  text-decoration: none;
  color: #73738d;
  font-weight: 400;
  font-size: 12px;
  padding: 6px 7px;
}
.input-box {
  border-radius: 4px;
  background: #f4f3f5;
  display: flex;
  padding: 6px 8px 6px 16px;
  align-items: center;
}
.email-selection {
  background-color: transparent;
  color: #73738d;
  font-size: 12px;
  font-weight: 400;
  border: none;
  border-radius: 4px;
  background: #f4f3f5;
  display: flex;
  padding: 6px 8px 6px 16px;
  align-items: center;
}
.email-selection:focus-visible {
  outline: 2px solid #4d1b7e;
}
.input-box select:focus-visible {
  outline: none;
}
.flow-sidebar {
  height: 100vh;
  width: 340px;
  position: fixed;
  top: 0px;
  left: 0px;
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.06);
}
.flow-content {
  background: #f4f3f5;
  position: absolute;
  right: 0px;
  width: calc(100% - 340px);
  min-height: 90vh;
  max-height: max-content;
}
.flow-card {
  display: flex;
  padding: 16px 20px;
  min-height: 120px;
  border-radius: 6px;
  cursor: move;
  /* border: 1px solid #4d1b7e; */
  background: rgba(77, 27, 126, 0.08);
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
}
.trigger-content-heading {
  color: #18191c;
  letter-spacing: 0.72px;
  text-transform: capitalize;
  font-weight: 500;
  font-size: 18px;
  letter-spacing: 0.72px;
}
.trigger-content-description {
  font-size: 14px;
  font-weight: 400;
  color: #73738d;
  letter-spacing: 0.56px;
  line-height: normal;
}
.segment-name {
  color: #18191c;
  font-weight: 600;
}
.ghost {
  opacity: 0.5;

  z-index: 1111;
}

.quizell-item-card::after {
  height: 20px;
  width: 1px;
  background: #d9d7db;
  content: "";
  display: block;
  margin: auto;
}
#collection-detail >>> button {
  background: transparent;
  border: none;
  margin: 0;
  padding: 0;
}
#collection-detail >>> button::after {
  display: none;
}
#collection-detail >>> ul {
  background: #ffffff;
  box-shadow: 0px 10px 20px -3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}
#collection-detail >>> li.topbarLink {
  color: #344054;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 5px;
}
#collection-detail >>> li a:active {
  color: #fff;
  text-decoration: none;
  background-color: #4d1b7e;
}

/* Add Node*/

.component-node-container {
  height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.component-node-container::after {
  content: "";
  width: 1px;
  height: 100%;
  background: #d9d7db;
  /* position: absolute;
    left: 152.5px;
    top: 0; */
  display: block;
  margin: auto;
}

.add-component-node {
  position: absolute;
  top: -2.5px;
  /* left: 151px; */
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 5px;
  background: #bac2ca;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: -ms-flex;
  display: flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  border-radius: 16px;
  /* position: relative; */
  font-size: 10px;
  color: #fff;
  z-index: 1;
}

.add-component-node.flowDragActive {
  top: -5px;
  width: 15px;
  height: 15px;
  background: #4d1b7e;
  color: #fff;
}
.add-component-node:hover {
  background: red !important;
}

.navRoute{
  color: #fff;
  font-size: 14px;
  cursor: pointer;
}
.navRoute:hover{
  color: #fff;
  text-decoration: none;
}

.headerSection{
  height:60px;
  position: fixed;
  top: 0;
  background: #4d1b7e;
  padding: 8px 16px;
  z-index: 1000 !important;
  
}

.custom-control-input:checked ~ .custom-control-label::before {
  background-color: #e0dee2 !important;
  border-color: #ced3d7 !important;
}
.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #ffa201 !important;
  border-color: #ced3d7 !important;
}
.route-enter-from {
opacity: 0;
transform: translateX(100px)
}
.route-enter-active {
transition: ass 0.3s ease-out;
}
.route-leave-to {
opacity: 0;
transform: translateX(-100px)}
.route-leave-active{
transition: all 0.3s ease-in;}
</style>
