<template>
 
  <div class="w-100">
     <Header heading="Create a new E-mail " class="pb-3" v-if="false">
      <template v-slot:buttons>
      <Button type="submit" :is-loading="isLoading">{{isEditMode?'Update Email':'Create Email'}}</Button>
      </template>
    </Header>
    <div class="card">
      <div class="card-body">

    
    <div class="row">
      <div class="col-md-5">
      <form @submit.prevent="submit()" autocomplete="off">
      <div
        class="d-flex flex-column align-items-center w-100"
        style="gap: 16px">
        <div class="w-100">
          <label
            for="name"
            class="d-flex  justify-content-between align-items-center"
            >
            <span class="text-uppercase">
               Name
            </span>
           
            <span class="text-danger">
              *{{ errors.name }}
            </span>
            </label
          >
          <input
            type="text"
            name=""
            v-model.trim="emailDetail.name"
            id="name"
            class="quizell-form-control w-100 px-2" />
        </div>
        <div class="w-100">
          <label
            for="subject-line"
            class="d-flex  justify-content-between align-items-center"
            ><span class="text-uppercase">
               Subject Line
            </span>
           
            <span class="text-danger">
              *{{ errors.subject }}
            </span></label
          >
          <input
            type="text"
            name=""
             v-model.trim="emailDetail.subject"
            id="subject-line"
            class="quizell-form-control w-100 px-2" />
        </div>
        <div class="w-100">
          <label
            for="preview-text"
            class="d-flex justify-content-between align-items-center"
            >
            <span class="text-uppercase">
               preview text
            </span>
           
            <span class="text-danger">
              *{{ errors.preview_text }}
            </span>
            </label
          >
          <input
            type="text"
            name=""
             v-model.trim="emailDetail.preview_text"
            id="preview-text"
            class="quizell-form-control w-100 px-2" />
        </div>
        <div class="w-100">
          <label
            for="sender-name"
            class="d-flex  justify-content-between align-items-center"
           
            > <span class="text-uppercase">
               Sender Name
            </span>
           
            <span class="text-danger">
              *{{ errors.sender_name }}
            </span></label
          >
          <input
            type="text"
            name=""
              v-model.trim="emailDetail.sender_name"
            id="sender-name"
            class="quizell-form-control w-100 px-2" />
        </div>
        <div class="w-100">
          <label
            for="sender-email-address"
            class="d-flex text-uppercase justify-content-between align-items-center"
            ><span class="text-uppercase">
               Sender Email Address
            </span>
           
            <span class="text-danger">
              *{{ errors.sender_email_address }}
            </span></label
          >
          <input
            type="email"
            name=""
            id="sender-email-address"
              v-model.trim="emailDetail.sender_email_address"
            class="quizell-form-control w-100 px-2" />
        </div>
        
        <div class="w-100" >
          <b-form-checkbox size="md" v-model="isReplayToEmail" class="checkbox d-flex align-items-center">
            <span style="font-size: 12px">
              Use this as your replay-to address</span
            ></b-form-checkbox
          >
        </div>
        <div class="w-100" v-if="isReplayToEmail">
          <label
            for="sender-email-address"
            class="d-flex  justify-content-between align-items-center"
            >
            <span class="text-uppercase">
               Reply To Email Address
            </span>
           
            <span class="text-danger">
              *{{ errors.reply_to_email_address }}
            </span></label
          >
          <input
            type="email"
            name=""
            id="sender-email-address"
              v-model.trim="emailDetail.reply_to_email_address"
            class="quizell-form-control w-100 px-2" />
        </div>
        
      </div>
      </form>
      </div>
      <div class="col-md-7">
             <div class="d-flex align-items-center justify-content-end">
  <b-dropdown split split-href="#foo/bar" text="Preview" class="m-2 quizell-dropdown">
    <b-dropdown-item href="#">Change Template</b-dropdown-item>
    <b-dropdown-item href="#">Create New Template</b-dropdown-item>
    
  </b-dropdown>
</div>
        <div class="d-flex h-100 w-100">
          <div class="card w-100">
            <div class="card-body h-100 w-100">
         
              <div class="d-flex h-100 justify-content-center flex-column align-items-center" style="cursor:pointer" @click="$router.push({name:'EmailEditor'})">
                <div class="icon">
              <img width="90" height="90" src="https://img.icons8.com/external-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto/90/external-drag-and-drop-website-development-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto.png" alt="external-drag-and-drop-website-development-yogi-aprelliyanto-detailed-outline-yogi-aprelliyanto"/>
                </div>
                <div class=" text-center">
                  <div class="" style="font-size:16px; font-weight:500"> Drag and Drop</div>
                  <div class=""  style="font-size:12px;"> Create an email using our drag and drop editor.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
      </div>
    </div>
  </div>
</template>

<script>

import Button from '../../../Layout/Button.vue';
import Header from '../../../Layout/Header.vue';

export default {
  components: {  Button,Header },
  props:{
    isEditMode:{
      type:Boolean,
      default:false
    },
    isLoading:Boolean,
    emailDetail:{
      type:Object,
      default:()=>({
    "name": "",
    "subject": "",
    "preview_text": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address":""

})

    },
     errors:{
      type:Object,
      default:()=>({
    "name": "",
    "subject": "",
    "preview_text": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address":""

})}

  },
  watch:{
    isReplayToEmail(newVal){
      if(!newVal)
{this.emailDetail.reply_to_email_address=''
this.errors.reply_to_email_address=''}
    }
  },
  data(){
    return {
      isReplayToEmail:false,
     
    }
  },
  methods: {
    open() {
      this.$refs.emailControl.open();
      this.errors={
    "name": "",
    "subject": "",
    "preview_text": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address":""

}
    },
    close() {
      this.$refs.emailControl.close();
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    submit(){
      if(this.validateEmailDetails()){
        this.$emit('store')
      }
    },
     validateEmailDetails() {
      this.errors = {}; // Clear previous errors
      // Validation logic
      if (!this.emailDetail.name) {
        this.errors.name = "required";
      }
      if (!this.emailDetail.subject) {
        this.errors.subject = "required";
      }
      if (!this.emailDetail.preview_text) {
        this.errors.preview_text = "required";
      }
      if (!this.emailDetail.sender_name) {
        this.errors.sender_name = "required";
      }
      if (!this.emailDetail.sender_email_address) {
        this.errors.sender_email_address = "required";
      } else if (!this.isValidEmail(this.emailDetail.sender_email_address)) {
        this.errors.sender_email_address = "Invalid email address";
      }
      if (!this.emailDetail.reply_to_email_address&&this.isReplayToEmail) {
        this.errors.reply_to_email_address = "required";
      } else if ((!this.isValidEmail(this.emailDetail.reply_to_email_address))&&this.isReplayToEmail) {
        this.errors.reply_to_email_address = "address";
      }

      if (Object.keys(this.errors).length === 0) {
       
        return  true;
      }
      return false
    },
  },
};
</script>

<style scoped>
.quizell-form-control {
  height: 48px;
  border-radius: 10px;
  border: 1px solid #d2d8e0;
  border-radius: 8px;
  outline: none;
  box-shadow: none;
  transition: 0.3s ease all;
  background-color: transparent;
}
.question-control {
  border: 1px solid rgba(255, 162, 1, 1);
}

.quizell-form-control:focus-visible {
  border: none !important;
  outline: none;
  box-shadow: 0 0 0 0.1rem #4d1b7e;
}
label {
  color: rgba(115, 115, 141, 1);
  font-size: 12px;
}
.checkbox >>> .custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #ffa201;
  background-color: #ffa201 !important;
}
.checkbox >>> .custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem #ffa201;
}
.quizell-dropdown>>> a.btn{
background: transparent !important;
color:#4d1b7e!important;
border: 1px solid #4d1b7e!important;
}
.quizell-dropdown>>> button{
 
background:#4d1b7e!important;
color: white;
/* border: 1px solid #4d1b7e!important; */
}

.quizell-dropdown>>> ul li a:active{
 
background:#4d1b7e!important;
color: white;
/* border: 1px solid #4d1b7e!important; */
}
</style>
