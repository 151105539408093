<template>
  <div class="mt-5 pt-4 mb-4 d-flex flex-column justify-content-between" style="height: 90%;">
    <div class="">
    <div class="d-flex p-2 text-uppercase font-weight-bold">
        Delay Time
    </div>
    <div class="px-3 mt-5">
        Set this delay for
        <div class="d-flex mt-2 justify-content-between">

            <input type="number" name="" v-model.trim.number="propObject.step_data.delay_number" class="delay-time-selection" placeholder="Number" id="">
            <select
                          name="email-selection "
                          v-model="propObject.step_data.delay_category"
                          id=""
                          class="delay-time-selection mx-2"
                        >
                          <option value="days">Day(s)</option>
                          <option value="hours">Hour(s)</option>
                          <option value="minutes">Minute(s)</option>
                          <option value="seconds">Second(s)</option>
                        </select>
        </div>
        <div class="mt-3">
           after the previous step. 
        </div>
    </div>
    </div>
    <div class=" px-3 d-flex justify-content-between">
        <Button class="cancel-button" @click="$emit('cancel')">Cancel</Button>
        <Button :is-loading="isLoading"  @click="$emit('submit',propObject)">Save</Button>
    </div>
  </div>
</template>

<script>
import Button from '../../../Layout/Button.vue';
export default {
  props:{
  selectedObject:Object,
  isLoading:Boolean
  },
components:{
    Button
},
data(){
let propObject= {...this.selectedObject}
return {
  propObject:propObject
}
},
}
</script>
<style scoped>
.delay-time-selection {

  outline: none;
    border: none;
    border-bottom: 1px solid #B2AFB6;;
    width: 100%;
    padding: 6px 0px;
    transition:0.5s ease all
}.delay-time-selection:focus{

    border-bottom: 1px solid #4D1B7E;;
   
}
.cancel-button{
    background: white;
    border: 2px solid  #4D1B7E;
    color:#4D1B7E;
}
</style>
