<template>

  <EmailTemplateSkeletion v-if="fetchingDataLoading"></EmailTemplateSkeletion>
    <EmailTemplate v-else ref="emailForm" :isLoading="isLoading" :errors="errors"  :emailDetail="emailDetail"></EmailTemplate>
</template>
<script>

import EmailTemplate from './Components/EmailTemplate.vue';
import { mapActions } from 'vuex';
import EmailTemplateSkeletion from './Components/EmailTemplateSkeletion.vue';
export default {

   components:{
    EmailTemplate,EmailTemplateSkeletion
   },
   props:{
    fetchingDataLoading:Boolean,
    emailDetail:
    {
      type:Object,
      default:()=>( {
    "name": "",
    "subject": "",
    "preview_text": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address": ""
})
    }
   },

   data(){
    return {

errors:{
    "name": "",
    "subject": "",
    "preview_text": "",
    "sender_name": "",
    "sender_email_address": "",
    "reply_to_email_address":""

},
isLoading:false,

    }
   },
  
   methods:{
    ...mapActions(['fetchData']),
    open(){
        this.$refs.emailForm.open();
  
    },
      validateEmailDetails() {
      this.errors = {}; // Clear previous errors
      // Validation logic
      if (!this.emailDetail.name) {
        this.errors.name = "required";
      }
      if (!this.emailDetail.subject) {
        this.errors.subject = "required";
      }
      if (!this.emailDetail.preview_text) {
        this.errors.preview_text = "required";
      }
      if (!this.emailDetail.sender_name) {
        this.errors.sender_name = "required";
      }
      if (!this.emailDetail.sender_email_address) {
        this.errors.sender_email_address = "required";
      } else if (!this.isValidEmail(this.emailDetail.sender_email_address)) {
        this.errors.sender_email_address = "Invalid email address";
      }
      if (!this.emailDetail.reply_to_email_address&&this.isReplayToEmail) {
        this.errors.reply_to_email_address = "required";
      } else if ((!this.isValidEmail(this.emailDetail.reply_to_email_address))&&this.isReplayToEmail) {
        this.errors.reply_to_email_address = "address";
      }

      if (Object.keys(this.errors).length === 0) {
       
        return  true;
      }
      return false
    },
     isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
   async  store(){
      if(!this.validateEmailDetails()){
       return 
      }
    
     this.$emit('startLoading')
     const data =await this.fetchData({
      axiosMethod:'put',
      resource:'email_templates',
      method:'update',
      params:this.emailDetail,
      id:this.emailDetail.id
    })
    
     if(data)
     {
     
      window.open('/EmailEditor/'+data.data.id, "_blank");
 this.toast('Email Template updated successfully');
            this.$emit('stopLoading',false)
            return 
     }
     this.toast('Error while updating email template');
     this.$emit('stopLoading',true)
      
        
    },
    toast(text){
          this.$toasted.show(text, {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
    }
   }
}
</script>